import { render, staticRenderFns } from "./error.vue?vue&type=template&id=289fc54f&scoped=true"
import script from "./error.vue?vue&type=script&lang=js"
export * from "./error.vue?vue&type=script&lang=js"
import style0 from "./error.vue?vue&type=style&index=0&id=289fc54f&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "289fc54f",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Icon: require('/code/tnaot_www_pc/components/Icon.vue').default})
