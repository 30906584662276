
import elementUIKmLocaleMessages from 'element-ui/lib/locale/lang/km'; // element-ui lang
export default {
  ...elementUIKmLocaleMessages,
  "No_more": "មិនមានផ្សេងទៀតទេ",
  "loading": "កំពុងផ្ទុក....",
  "Request_failed_click_to_upload_again": "ការស្នើបានបរាជ័យ ចុចសាកល្បងម្តងទៀត",
  "if_News_discover_accepted_reward_$5-100": "ផ្តល់ព័ត៌មានអាចទទួលបាន5-100$",
  "Phone_discover": "ផ្តល់ព័ត៌មានតាមទូរសព្ទ",
  "Wechat_discover": "ផ្តល់ព័ត៌មានតាមWechat",
  "Copy_successfully": "ចម្លងរួច",
  "Copy_failed_try_again": "ចម្លងបានបរាជ័យសូមព្យាយាមម្តងទៀត",
  "Best_recommend": "ការណែនាំពិសេស",
  "Open_APP_read_full_text_get_highest_$5": "បើកAPPដើម្បីអានបន្ត ទទួលបានដល់ទៅ 5$",
  "Your_browser_does_not_support_video_hashtag": "កម្មវិធីបើកមើលរបស់អ្នកមិនគាំទ្រថេកវីដេអូ",
  "Discover_get_reward": "ផ្តល់ព័ត៌មានបានរង្វាន់",
  "Download_APP": "ទាញយកAPP",
  "slow": "អ៊ីនធឺណែតយឺត",
  "Click_to_retry": "ចុចសាកល្បងម្តងទៀត",
  "Open_App_watch_video_get_highest_XXX": "បើកAPPមើលវីដេអូខ្លីទទួលបានដល់ទៅ 5$",
  "download_App_watch_video_get_highest_XXX": "ទាញយកAPPមើលវីដេអូ អាចទទួលបាន5$",
  "Open_APP_to_read_the_complete_news": "បើកAPPអានបន្ត",
  "download_app_to_read": "ទាញយកAPPអានបន្ត ទទួលបទពិសោធន៍ថ្មី",
  "download_app_to_read_full_text_get_highest_$5": "ទាញយកAPPអានបន្ត អាចទទួលបាន5$",
  "Open_APP_to_get_more_fun": "បើកAPPមើលវីដេអូខ្លី ទទួលយកបទពិសោធន៍ល្អៗ",
  "download_APP_to_get_more_fun": "ទាញយកAPPមើលវីដេអូ ទទួលបទពិសោធន៍ថ្មី",
  "wechat_Open_in_Browser": "សូមចុចខាងលើនេះ ហើយចុច</br>“Open in Browser”",
  "Twitter_Open_in_Browser": "បើកកម្មវិធីស្វែងរក​​",
  "Scan_QR_code": "ស្កេនQRកូដទាញយក រីករាយនឹងព័ត៌មានដ៏សម្បូរបែប",
  "More_Link": "តំណភ្ជាប់មិត្តភាព",
  "Info_channel": "ឆានែលព័ត៌មាន",
  "Play": "ចាក់",
  "Read": "អាន",
  "Share_with": "ចែករំលែកទៅកាន់៖ ",
  "Cambodia_TNAOT": "TNAOT APP",
  "Khmer_information": "ព័ត៌មានទាន់ហេតុការណ៍នៅកម្ពុជា",
  "tnaot_information": "1、TNAOT APP គឺជា APP ទូរសព្ទដែលមានខ្លឹមសារព័ត៌មានគ្រប់ជ្រុងជ្រោយមួយដែលស្រាវជ្រាវ 2、និងបង្កើតឡើងដោយយកចិត្តទុកដាក់សម្រាប់អតិថិជន កំណត់ខ្លឹមសារចំពោះមុខជូន 3、អតិថិជនតាមរយៈបច្ចេកទេសវៃឆ្លាត ផ្តល់ជូនអ្នកនូវ Vlog ដ៏គួរឱ្យចាប់ចិត្ត វីដេអូប្លែកៗ ព័ត៌មានទាន់ 4、ហេតុការណ៍ និង ព័ត៌មានបច្ចុប្បន្នភាពសកលជាដើម។",
  "Scan_to_download": "ស្កេនQRកូដដើម្បីទាញយក",
  "IOS_down": "IOS",
  "Andriod_down": "Android",
  "Tnaot": "TNAOT-All in One",
  "Harbor_Property": "Harbor Property",
  "tnaot_beian": "粤ICP备18129079号-2",
  "Open_Wechat_to_scan": "បើកWeChatប្រើមុខងារស្កេន",
  "Open_Wechat_main_page": "បើកទំព័រដើមWeChat ចុចលើសញ្ញា“+”នៅជ្រុងស្តាំខាងលើ ប្រើមុខងារស្កេនដើម្បីចែករំលែកទៅមិត្តភក្តិរបស់អ្នក",
  "indexKeywords": "សារព័ត៌មានត្នោត ខេមរភាសា ព័ត៌មាន ខ្មែរចិន ប្រព័ន្ធផ្សព្វផ្សាយ ក្នុងស្រុក ពីរភាសា កម្ពុជា ដំណើរកម្សាន្ដ កីឡាជាតិ",
  "indexDescription": " សារព័ត៌មានត្នោត ប្រព័ន្ធផ្សព្វបែបឌីជីថល មានវិសាលភាពធំទូលាយ មាតិកាពីរភាសាខ្មែរចិន ដើម្បីរួមចំណែកអភិវឌ្ឍន៍វិស័យព័ត៌មានឱ្យ​កាន់តែរីកចម្រើន និងផ្សារភ្ជាប់កម្ពុជាទៅនឹងសកលលោកទាំងមូល។",
  "tnaot_title": "TNAOT APP បណ្ដុំព័ត៌មានជាភាសាខ្មែរ ចិន",
  "2020_TNAOT": "Copyright © 2020",
  "Kohthmey_TNAOT": "Kohthmey Technology Co., Ltd",
  "404_Tip": "សូមអភ័យទោស! ទំព័រដែលអ្នកបានចូលមិនមាននោះទេ",
  "Wrong_prompt": "ការបញ្ចូលតំណភ្ជាប់ខុសឬតំណភ្ជាប់ត្រូវបានលុបចោល",
  "Back_Tip": "Xs ត្រលប់ទៅទំព័រដើម",
  see_more: 'មើល​បន្ថែម​ទៀត',
  home_page: 'ទំព័រ​ដើម',
  focus_news: 'ព្រឹត្តិការណ៍សំខាន់ៗ',
  publish_works: 'បង្ហោះផ្សាយ',
  top_news: 'ព័ត៌មានកំណត់លើគេបង្អស់',
  hot_topics: 'ប្រធានបទពេញនិយម',
  latest_information: 'ព័ត៌មានថ្មីៗ',
  all_comments: 'យោបល់ទាំងអស់',
  no_comment: 'គ្មាន​យោបល់',
  view_all_replies: 'ពិនិត្យការឆ្លើយតបទាំងអស់',
  request_error_please_click_to_try_again: 'ការស្នើសុំមានកំហុស សូមសាកល្បងចុងម្ដងទៀត',
  refresh: 'Refresh',
  search_keyword: 'ស្វែងរកពាក្យគន្លឹះ',
  search: 'ស្វែងរក',
  tas_popular_works: 'ស្នាដៃពេញនិយមរបស់គាត់',
  xxx_content: 'មាតិកា',
  related_topics: 'ប្រធានបទពាក់ព័ន្ទ',
  no_content_temporarily: 'មិនទាន់មានមាតិកានៅឡើយទេ',
  sorry_no_results_related_to_search_keywords_were_found: 'សូមទោស "XXX" មិនត្រូវបានរកឃើញ',
  reminder: 'សេចក្ដីរំលឹក',
  please_check_whether_the_input_is_correct: 'សូមពិនិត្យមើលថាតើមាតិកាបញ្ចូលត្រឹមត្រូវឬអត់?',
  try_again_after_changing_keywords: 'សូមព្យាយាមម្ដងទៀត បន្ទាប់ពីផ្លាស់ប្តូរពាក្យគន្លឹះរួច',
  disclaimers: 'សេចក្តីថ្លែងការណ៍លើកលែង',
  this_article_is_from_cambodia: 'អត្ថបទនេះបានមកពីអ្នកប្រើប្រាស់របស់ TNAOT APP មិនតំណាងឱ្យទស្សនៈ និង​គោលជំហរណាមួយរបស់យើងខ្ញុំឡើយ។ ប្រសិនបើមានបញ្ហាបំពានកម្មសិទ្ធិ សូមទាក់ទងមកកាន់យើងខ្ញុំដើម្បីបញ្ជាក់ការលុប។',
  contact_us: 'ទាក់ទង​មក​ពួក​យើង',
  telephone: 'លេខទូរស័ព្ទ',
  mailbox: 'ប្រអប់សារ',
  user_feedback: 'មតិយោបល់អ្នកប្រើប្រាស់',
  please_open_tnaot_app_and_go_to_menu: 'សូមបើកកម្មវិធី TNAOT ហើយចូលទៅកាន់ "Menu"',
  suggestion_feedback: 'ធ្វើការឆ្លើយតបមតិយោបល់',
  follow_us: 'តាម​ដានពួក​យើង',
  cambodian_chinese_community: 'សហគមន៍ចិននៅកម្ពុជា',
  privacy_policy: 'គោលការណ៍​ភាព​ឯកជន',
  update_time: 'ធ្វើបច្ចុប្បន្នភាពពេលវេលា',
  topic_details: 'ព័ត៌មានលម្អិតអំពី Special Page',
  welcome_title: 'សូមស្វាគមន៍មកកាន់ TNAOT.COM',
  all: "ទាំងអស់",
  article: 'អត្ថបទរូបភាព',
  video: 'អត្ថបទ',
  vlog: 'Vlog',
  just_now: 'អំបាញ់មិញ',
  hours_ago: '​ម៉ោង​មុន',
  yesterday: 'ម្សិលមិញ',
  cooperation_in_ad: 'កិច្ចសហប្រតិបត្តិការផ្សព្វផ្សាយ',
  i_draw_the_line: 'ពុំមានមាតិកាបន្ដទៀតទេ'
}