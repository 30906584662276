
import TFooter from '../components/TFooter.vue';
import BackToTop from '../components/BackToTop.vue';
import HeaderNav from '../components/HeaderNav.vue';
import { NAV_TOP_DISTANCE } from '@/constants/index.js';

export default {
  name: 'LayoutPage',

  components: { HeaderNav, BackToTop, TFooter },

  data() {
    return {
      minHeight: 0
    };
  },

  mounted() {
    this.init();
  },

  methods: {
    init() {
      if (process.server) return;
      this.getSize();
      window.addEventListener('resize', this.getSize);
    },

    removeEventListener() {
      if (process.server) return;
      window.removeEventListener('resize', this.getSize);
    },

    getSize() {
      const header = NAV_TOP_DISTANCE;
      const footer = 64;
      const weird = 3;
      this.minHeight = window.innerHeight - header - footer - weird;
    }
  },

  errorCaptured(error, vm) {
    vm.$sentry.captureException(error);
    const e = error instanceof Error ? error : new Error(error);
    console.error(e);
    if (process.client) return false;
    return true;
  }
};
