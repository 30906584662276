import { render, staticRenderFns } from "./TFooter.vue?vue&type=template&id=597317c8&scoped=true"
import script from "./TFooter.vue?vue&type=script&lang=js"
export * from "./TFooter.vue?vue&type=script&lang=js"
import style0 from "./TFooter.vue?vue&type=style&index=0&id=597317c8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "597317c8",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MxLink: require('/code/tnaot_www_pc/components/MxLink.vue').default})
