
export default {
  name: 'ErrorPage',

  data() {
    return {
      isHomePage: true
    };
  },

  mounted() {
    const homePath = this.localePath('/', this.$i18n.locale);
    const { path } = this.$route;
    const isHomePage = homePath === path || path === '/';
    this.isHomePage = isHomePage;

    if (isHomePage) {
      return;
    }

    setTimeout(() => {
      this.$router.push({
        path: this.localePath('/', this.$i18n.locale)
      });
    }, 5000);
  }
};
