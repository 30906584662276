import Vue from 'vue';
import * as filters from '@/filters/index.js'

Object.keys(filters).forEach(key => {
  // eslint-disable-next-line import/namespace
  Vue.filter(key, filters[key]);
});

export default (context) => {
  Object.keys(filters).forEach(key => {
    // eslint-disable-next-line import/namespace
    Vue.filter(key, (...rest) => filters[key](context, ...rest));
  });
};
