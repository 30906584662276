
function normalizeSize(size) {
  if (typeof size === 'number') return `${size}px`;
  if (typeof size === 'string') return size;
  return '';
}

export default {
  name: 'SvgIcon',
  props: {
    name: {
      type: String,
      required: true
    },
    className: {
      type: String,
      default: ''
    },
    size: {
      type: [Number, String],
      default: ''
    },
    width: {
      type: [Number, String],
      default: ''
    },
    height: {
      type: [Number, String],
      default: ''
    }
  },
  computed: {
    iconName() {
      const { name } = this;
      if (/^https?:\/\//i.test(name)) return name;
      return `#icon-${name}`;
    },
    svgClass() {
      return this.className ? 'svg-icon ' + this.className : 'svg-icon';
    },
    style() {
      const size = normalizeSize(this.size);
      const width = normalizeSize(this.width);
      const height = normalizeSize(this.height);
      const style = {};
      if (size) {
        style.width = size;
        style.height = size;
      }
      if (width) style.width = width;
      if (height) style.height = height;
      return style;
    }
  },

  methods: {
    click() {
      this.$emit('click');
    }
  }
};
