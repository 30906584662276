import {
  getArticleSeoHead,
  getVideoSeoHead,
  getGallerySeoHead,
  getMainSeoHead,
  mergeSeoHead,
} from "@/seo/index";

const LOCALE_MAP = {
  km: "km_KH",
  zh: "zh_CN",
};

const SITE_NAME = {
  zh: "柬埔寨头条",
  km: "TNAOT",
};

function getSiteName(locale) {
  return SITE_NAME[locale] || SITE_NAME[km];
}

function getServerRequestUrlObject(req) {
  const { URL } = require("url");
  const url = new URL(req.url, `https://${req.headers.host}`);
  return url;
}

function getServerRequestUrl(req) {
  const url = getServerRequestUrlObject(req);
  return url.href;
}

function suffixTittle(title, siteName) {
  if (!title) return siteName;
  return `${title} - ${siteName}`;
}

function getMainEntityOfPage(req) {
  const url = getServerRequestUrlObject(req);
  url.host = "www.tnaot.com";
  return url.href;
}

function getMobileUrl(req) {
  const url = getServerRequestUrlObject(req);
  url.host = "m.tnaot.com";
  return url.href;
}

function injectData(ctx, data) {
  const { locale } = ctx.app.i18n;
  const siteName = getSiteName(locale);

  const result = {
    ...data,
    siteName,
    locale,
    title: suffixTittle(data.title, siteName),
    pageLocale: LOCALE_MAP[locale],
  };

  if (process.server) {
    result.pageUrl = getServerRequestUrl(ctx.req);
    result.canonicalPageUrl = getMainEntityOfPage(ctx.req);
    result.mobileUrl = getMobileUrl(ctx.req);
  }

  return result;
}

export default (context, inject) => {
  const wrapMethod = (f) => (data) => f(injectData(context, data), context);

  const injectMethod = (name, method) => {
    inject(name, method);
    context[`\$${name}`] = method;
  };
  const getHeadMethods = {
    mergeSeoHead,
    getArticleSeoHead: wrapMethod(getArticleSeoHead),
    getVideoSeoHead: wrapMethod(getVideoSeoHead),
    getGallerySeoHead: wrapMethod(getGallerySeoHead),
    getMainSeoHead: wrapMethod(getMainSeoHead),
  };

  Object.keys(getHeadMethods).forEach((name) => {
    injectMethod(name, getHeadMethods[name]);
  });
};
