
export default {
  props: {
    href: {
      type: [Object, String],
      require: true,
      default: ''
    },
    to: {
      type: [Object, String],
      require: true,
      default: ''
    },
    withLocale: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    uniHref() {
      const url = this.href || this.to || '';
      return this.withLocale ? this.localePath(url, this.$i18n.locale) : url;
    },
    url() {
      const href = this.uniHref;
      if (typeof href === 'string') return href;
      if (typeof href === 'object' && href) return href.path;
      return '';
    },
    isExternalLink() {
      const { url } = this;
      if (!url || typeof url !== 'string') return false;
      return /^https?:\/\//.test(url);
    },
    target() {
      return this.isExternalLink ? '_blank' : '_self';
    }
  }
};
