import API from '../api/index';
import { pick } from '../utils/helper';

const keys = Object.keys(API).filter(k => typeof API[k] === 'function');

function wrapApiWithContext(context) {
  return {
    ...API,
    ...keys.reduce((o, k) => {
      const fn = API[k];
      o[k] = function wrapperApi(...rest) {
        return fn(context, ...rest);
      };
      return o;
    }, {})
  };
}

export default (context, inject) => {
  // Inject $hello(msg) in Vue, context and store.
  inject('API', wrapApiWithContext(context));
  inject('pick', pick);
};
