import elementUIZhLocaleMessages from 'element-ui/lib/locale/lang/zh-CN'; // element-ui lang
export default {
  ...elementUIZhLocaleMessages,
  No_more: '没有更多了',
  loading: '加载中⋅⋅⋅',
  Request_failed_click_to_upload_again: '请求失败，点击重新加载',
  'if_News_discover_accepted_reward_$5-100': '爆料或可获得$5-100',
  Phone_discover: '电话爆料',
  Wechat_discover: '微信爆料',
  Copy_successfully: '复制成功',
  Copy_failed_try_again: '复制失败，请重试',
  Best_recommend: '精彩推荐',
  Open_APP_read_full_text_get_highest_$5: '打开APP阅读全文，最高得 $5',
  Your_browser_does_not_support_video_hashtag: '您的浏览器不支持 video 标签',
  Discover_get_reward: '爆料有奖',
  Download_APP: '下载APP',
  slow: '网络不给力',
  Click_to_retry: '点击重试',
  Open_App_watch_video_get_highest_XXX: '打开APP看视频，最高得 $5',
  download_App_watch_video_get_highest_XXX: '下载APP看视频，最高可得$5',
  Open_APP_to_read_the_complete_news: '打开APP阅读全文',
  download_app_to_read: '下载APP阅读，体验更精彩',
  download_app_to_read_full_text_get_highest_$5: '下载APP阅读，最高可得$5',
  Open_APP_to_get_more_fun: '打开APP看视频，体验更精彩',
  download_APP_to_get_more_fun: '下载APP看视频，体验更精彩',
  wechat_Open_in_Browser: '点击右上角“…”按钮，选择浏览器打开',
  Twitter_Open_in_Browser: '请用浏览器打开',
  Scan_QR_code: '扫二维码下载畅享海量资讯',
  More_Link: '友情链接',
  Info_channel: '资讯频道',
  Play: '播放',
  Read: '阅读',
  Share_with: '分享至：',
  Cambodia_TNAOT: 'TNAOT 柬埔寨头条',
  Khmer_information: '及时有用的在柬资讯',
  tnaot_information:
    '柬埔寨头条（TNAOT）是为旅居柬埔寨的华人华侨及柬埔寨国内众多用户精心研发度身打造的一款资讯和生活服务手机app，及时提供柬埔寨的各类资讯、生活信息、商业服务等，让您随时随地了解柬埔寨最新动态。',
  Scan_to_download: '扫二维码下载',
  IOS_down: 'IOS 下载',
  Andriod_down: 'Android 下载',
  Tnaot: '柬埔寨头条-TNAOT',
  Harbor_Property: '港湾置业',
  tnaot_beian: '粤ICP备18129079号-2',
  Open_Wechat_to_scan: '请打开微信扫一扫',
  Open_Wechat_main_page:
    '打开微信首页，点击右上角“+”，使用“扫一扫”即可将网页分享至朋友圈',
  indexKeywords:
    '柬、柬埔寨、柬埔寨头条、柬埔寨地图、金边、中文媒体、柬文媒体、高棉生活、话费充值。',
  indexDescription:
    '《柬埔寨头条》中柬双语内容平台，承接全媒体投放，柬埔寨本土最活跃APP，及时提供柬埔寨的各类资讯、生活攻略、商业服务、政策解读，是柬埔寨最全内容平台。',
  tnaot_title:
    '柬埔寨头条柬埔寨中柬媒体|柬埔寨商业动态|柬埔寨房产|柬埔寨新闻|柬埔寨生活攻略 - tnaot.com',
  '2020_TNAOT': '© 2020 TNAOT',
  Kohthmey_TNAOT: '粤ICP备18129079号-2',
  Kohthmey_TNAOT_TWO: '粤ICP备2024293432号-1',
  '404_Tip': '很抱歉！你访问的页面不存在',
  Wrong_prompt: '输入地址有误或该地址已被删除',
  Back_Tip: 'Xs 后自动 回到首页',
  see_more: '查看更多',
  home_page: '首页',
  focus_news: '要闻',
  publish_works: '发布作品',
  top_news: '置顶新闻',
  hot_topics: '热门专题',
  latest_information: '最新资讯',
  all_comments: '全部评论',
  no_comment: '暂无评论',
  view_all_replies: '查看全部回复',
  request_error_please_click_to_try_again: '请求出错，请点击重试',
  refresh: '刷新',
  search_keyword: '搜索关键字',
  search: '搜索',
  tas_popular_works: 'TA的热门作品',
  xxx_content: '篇内容',
  related_topics: '关联专题',
  no_content_temporarily: '暂无内容',
  sorry_no_results_related_to_search_keywords_were_found:
    '抱歉，未找到"XXX”相关结果',
  reminder: '温馨提示',
  please_check_whether_the_input_is_correct: '请检查输入内容是否正确',
  try_again_after_changing_keywords: '更换关键词后再尝试',
  disclaimers: '免责声明',
  this_article_is_from_cambodia:
    '本文来自柬埔寨头条客户端自媒体，不代表柬埔寨头条观点和立场。如存在侵权问题，联系立删。',
  contact_us: '联系我们',
  telephone: '电话',
  mailbox: '邮箱',
  user_feedback: '用户反馈',
  please_open_tnaot_app_and_go_to_menu: '请打开 TNAOT APP，前往 “菜单”',
  suggestion_feedback: '“建议反馈” 进行反馈。',
  follow_us: '关注我们',
  cambodian_chinese_community: '柬埔寨中文社区',
  privacy_policy: '隐私政策',
  update_time: '更新时间',
  topic_details: '专题详情',
  welcome_title: '欢迎访问柬埔寨头条',
  all: '全部',
  article: '文章 ',
  video: '视频',
  vlog: '小视频',
  just_now: '刚刚',
  hours_ago: '小时前',
  yesterday: '昨天',
  cooperation_in_ad: '广告合作',
  i_draw_the_line: '这是有底线的'
};
