export default [
  {
    // 首页置顶新闻
    path: '/article/get_top_news',
    params: {
    }
  },
  {
    // 首页 每日精选
    path: '/article/get_selected_news',
    params: {
    }
  },
  {
    // 首页 获取频道名称列表
    path: '/article/get_channel',
    params: {}
  },
  {
    // 首页 获取频道 内容列表
    path: '/article/get_channel_list_content',
    params: {}
  },
  {
    // 热门专题
    path: '/subject/list_hots',
    params: {
      subjectId: '', // 只有专题详情页的热门专题需要传值，用于去除当前专题
    }
  },
  {
    // 最新资讯
    path: '/article/get_lastest_news',
    params: {
    }
  },
  {
    // 用户的热门资讯
    path: '/article/get_author_hot_news',
    params: {
    }
  },
  {
    // 详情页获取精选内容
    path: '/article/get_related_list',
    params: {
    }
  },
  {
    // 资讯评论
    path: '/review/get_review_list',
    params: {
      newsId: '',
      pageNum: 1,
      pageSize: 15
    }
  },
  {
    // 获取频道详情数据
    path: '/article/get_channel_list',
    params: {
      channelId: '',
    }
  },
  {
    // 资讯详情
    path: '/article/get_news_detail',
    params: {
      news_id: '',
      news_type: ''
    }
  },
  {
    // 搜索
    path: '/article/search',
    params: {
      keyword: '',
      type: '', // 0:：综合，1：图文，3：视频，8：小视频
      pageNum: '',
      pageSize: ''
    }
  },
  {
    // 获取安卓下载地址
    path: '/download/app/android',
    params: {

    }
  },
  {
    // 特色出品
    path: '/subject/list_featureds',
    params: {
    }
  },
  {
    // 专题详情的头部简介
    path: '/subject/get_detail',
    params: {
      subjectId: ''
    }
  },
  {
    // 专题详情的内容列表
    path: '/subject/list_contents',
    params: {
      topicId: '',
      pageNum: 1,
      pageSize: 15
    }
  },
  {
    // banner接口
    path: '/article/get_banner_list',
    params: {
      sourceType: '', // 16首页 17详情 19文章开头
    }
  },

];
