import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _9374ba72 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _2ae07c94 = () => interopDefault(import('../pages/m/contactus/index.vue' /* webpackChunkName: "pages/m/contactus/index" */))
const _ec0d1bf0 = () => interopDefault(import('../pages/m/download/index.vue' /* webpackChunkName: "pages/m/download/index" */))
const _a25cea9c = () => interopDefault(import('../pages/m/friendly-link/index.vue' /* webpackChunkName: "pages/m/friendly-link/index" */))
const _607ad39e = () => interopDefault(import('../pages/m/privacy/index.vue' /* webpackChunkName: "pages/m/privacy/index" */))
const _e71e0730 = () => interopDefault(import('../pages/m/search/index.vue' /* webpackChunkName: "pages/m/search/index" */))
const _50b7a488 = () => interopDefault(import('../pages/m/topic-list/index.vue' /* webpackChunkName: "pages/m/topic-list/index" */))
const _0b168f60 = () => interopDefault(import('../pages/m/detail/article/_id.vue' /* webpackChunkName: "pages/m/detail/article/_id" */))
const _002ad668 = () => interopDefault(import('../pages/m/detail/video/_id/index.vue' /* webpackChunkName: "pages/m/detail/video/_id/index" */))
const _336af28a = () => interopDefault(import('../pages/m/detail/vlog/_id.vue' /* webpackChunkName: "pages/m/detail/vlog/_id" */))
const _4987d401 = () => interopDefault(import('../pages/m/channel/_id.vue' /* webpackChunkName: "pages/m/channel/_id" */))
const _83132a26 = () => interopDefault(import('../pages/m/topic/_id.vue' /* webpackChunkName: "pages/m/topic/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/en",
    component: _9374ba72,
    name: "index___en"
  }, {
    path: "/km",
    component: _9374ba72,
    name: "index___km"
  }, {
    path: "/zh",
    component: _9374ba72,
    name: "index___zh"
  }, {
    path: "/en/m/contactus",
    component: _2ae07c94,
    name: "m-contactus___en"
  }, {
    path: "/en/m/download",
    component: _ec0d1bf0,
    name: "m-download___en"
  }, {
    path: "/en/m/friendly-link",
    component: _a25cea9c,
    name: "m-friendly-link___en"
  }, {
    path: "/en/m/privacy",
    component: _607ad39e,
    name: "m-privacy___en"
  }, {
    path: "/en/m/search",
    component: _e71e0730,
    name: "m-search___en"
  }, {
    path: "/en/m/topic-list",
    component: _50b7a488,
    name: "m-topic-list___en"
  }, {
    path: "/km/m/contactus",
    component: _2ae07c94,
    name: "m-contactus___km"
  }, {
    path: "/km/m/download",
    component: _ec0d1bf0,
    name: "m-download___km"
  }, {
    path: "/km/m/friendly-link",
    component: _a25cea9c,
    name: "m-friendly-link___km"
  }, {
    path: "/km/m/privacy",
    component: _607ad39e,
    name: "m-privacy___km"
  }, {
    path: "/km/m/search",
    component: _e71e0730,
    name: "m-search___km"
  }, {
    path: "/km/m/topic-list",
    component: _50b7a488,
    name: "m-topic-list___km"
  }, {
    path: "/zh/m/contactus",
    component: _2ae07c94,
    name: "m-contactus___zh"
  }, {
    path: "/zh/m/download",
    component: _ec0d1bf0,
    name: "m-download___zh"
  }, {
    path: "/zh/m/friendly-link",
    component: _a25cea9c,
    name: "m-friendly-link___zh"
  }, {
    path: "/zh/m/privacy",
    component: _607ad39e,
    name: "m-privacy___zh"
  }, {
    path: "/zh/m/search",
    component: _e71e0730,
    name: "m-search___zh"
  }, {
    path: "/zh/m/topic-list",
    component: _50b7a488,
    name: "m-topic-list___zh"
  }, {
    path: "/en/m/detail/article/:id?",
    component: _0b168f60,
    name: "m-detail-article-id___en"
  }, {
    path: "/en/m/detail/video/:id",
    component: _002ad668,
    name: "m-detail-video-id___en"
  }, {
    path: "/en/m/detail/vlog/:id?",
    component: _336af28a,
    name: "m-detail-vlog-id___en"
  }, {
    path: "/km/m/detail/article/:id?",
    component: _0b168f60,
    name: "m-detail-article-id___km"
  }, {
    path: "/km/m/detail/video/:id",
    component: _002ad668,
    name: "m-detail-video-id___km"
  }, {
    path: "/km/m/detail/vlog/:id?",
    component: _336af28a,
    name: "m-detail-vlog-id___km"
  }, {
    path: "/zh/m/detail/article/:id?",
    component: _0b168f60,
    name: "m-detail-article-id___zh"
  }, {
    path: "/zh/m/detail/video/:id",
    component: _002ad668,
    name: "m-detail-video-id___zh"
  }, {
    path: "/zh/m/detail/vlog/:id?",
    component: _336af28a,
    name: "m-detail-vlog-id___zh"
  }, {
    path: "/en/m/channel/:id?",
    component: _4987d401,
    name: "m-channel-id___en"
  }, {
    path: "/en/m/topic/:id?",
    component: _83132a26,
    name: "m-topic-id___en"
  }, {
    path: "/km/m/channel/:id?",
    component: _4987d401,
    name: "m-channel-id___km"
  }, {
    path: "/km/m/topic/:id?",
    component: _83132a26,
    name: "m-topic-id___km"
  }, {
    path: "/zh/m/channel/:id?",
    component: _4987d401,
    name: "m-channel-id___zh"
  }, {
    path: "/zh/m/topic/:id?",
    component: _83132a26,
    name: "m-topic-id___zh"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
