import { getUserAgentLanguage } from '@/utils/language';

function getValidLang(lang) {
  if (typeof lang !== 'string') return '';
  if (/zh/i.test(lang)) return 'zh';
  if (/km/i.test(lang)) return 'km';
  if (/en/i.test(lang)) return 'km';
  return '';
}

function getPreferUserAgentLang(req) {
  const lang = getUserAgentLanguage(req);
  if (typeof lang !== 'string') return '';
  const [prefer] = lang.split(',');
  return prefer;
}

function safeGetQueryLang(lang) {
  if (Array.isArray(lang)) {
    return safeGetQueryLang(lang[0]);
  }
  if (typeof lang == 'string') return lang;
  return '';
}

// '/zh/m/channel/131'
function getPathLan(path) {
  if (!path) return ''
  return path.split('/')[1]
}

export default function ({
  isHMR,
  app,
  store,
  route,
  params,
  error,
  redirect,
  req,
  cookies,
}) {
  if (isHMR) return;

  getPathLan(route.path)

  let locale = getValidLang(params.lang);
  if (!locale) locale = getValidLang(safeGetQueryLang(getPathLan(route.path)));
  if (!locale) locale = getValidLang(getPreferUserAgentLang(req));
  if (!locale) locale = getValidLang(app.i18n.fallbackLocale);
  if (!locale) locale = 'km';

  // Set locale
  store.commit('app/updateLanguage', locale);
  app.i18n.setLocale(locale);
  app.i18n.locale = locale;
}
