
import PlaceholderImage from '../assets/images/avatar.png';

export default {
  props: {
    src: [Number, String],
    defaultImage: {
      type: String,
      default: PlaceholderImage
    },
    defaultErrorImage: {
      type: String,
      default: PlaceholderImage
    }
  },

  data() {
    return {
      errorImage: ''
    };
  },

  computed: {
    url() {
      return this.errorImage || this.src || this.defaultImage;
    }
  },

  methods: {
    onImageError() {
      this.errorImage = this.defaultErrorImage;
    }
  },

  render() {
    return (
      <img
        {...{ attrs: { ...this.$attrs } }}
        src={this.url}
        vOn:error={this.onImageError}
      />
    );
  }
};
