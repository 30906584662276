
import MxLink from './MxLink.vue';
import { LANGUAGE } from '@/constants/index';

export default {
  components: {
    MxLink
  },

  data() {
    return {
      val: null
    };
  },
  mounted() {
    this.val = window.location.href.indexOf('tnaot.cn');
  },

  computed: {
    language() {
      return this.$store.state.app.language;
    },
    links() {
      if (this.language === LANGUAGE.ZH) {
        return [
          {
            text: 'Download_APP',
            link: '/m/download',
            withLocale: true
          },
          {
            text: 'privacy_policy',
            link: '/m/privacy',
            withLocale: true
          },
          {
            text: 'cooperation_in_ad',
            link: 'https://kh.tnaot.com/ad',
            withLocale: false
          },
          {
            text: 'contact_us',
            link: '/m/contactus',
            withLocale: true
          },
          {
            text: 'More_Link',
            link: '/m/friendly-link',
            withLocale: true
          }
        ];
      }

      return [
        {
          text: 'Download_APP',
          link: '/m/download',
          withLocale: true
        },
        {
          text: 'privacy_policy',
          link: '/m/privacy',
          withLocale: true
        },
        {
          text: 'cooperation_in_ad',
          link: 'https://kh.tnaot.com/ad-en',
          withLocale: false
        },
        {
          text: 'contact_us',
          link: '/m/contactus',
          withLocale: true
        },
        {
          text: 'More_Link',
          link: '/m/friendly-link',
          withLocale: true
        }
      ];
    }
  }
};
