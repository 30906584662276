const LANGUAGE_MAP = {
  ZH: {
    name: '中文',
    lt: 'zh-Hans', // language tag
    code: 'zh'
  },
  KM: {
    name: 'ភាសាខ្មែរ',
    lt: 'km-KH',
    code: 'km'
  }
};

const LANGUAGE = {
  ZH: 'zh',
  KM: 'km'
}

const LANGUAGES = [
  { label: '简体中文', value: 'zh' },
  { label: 'ខ្មែរ', value: 'km' }
];

const NEWS_TYPE = {
  ARTICLE: 1,
  VIDEO: 3,
  VLOG: 8
}
const NEWS_TYPE_LABEL = {
  [NEWS_TYPE.ARTICLE]: 'article',
  [NEWS_TYPE.VIDEO]: 'video',
  [NEWS_TYPE.VLOG]: 'vlog'
}

const NEWS_PAGE_PATH_MAP = {
  [NEWS_TYPE.ARTICLE]: '/m/detail/article',
  [NEWS_TYPE.VIDEO]: '/m/detail/video',
  [NEWS_TYPE.VLOG]: '/m/detail/vlog'
}

// 顶部高度
const NAV_TOP_DISTANCE = 64;

export {
  NEWS_TYPE,
  NEWS_TYPE_LABEL,
  NEWS_PAGE_PATH_MAP,
  LANGUAGE,
  LANGUAGES,
  LANGUAGE_MAP,
  NAV_TOP_DISTANCE
}