// eslint-disable-next-line import/no-cycle
import makeApi from './request';
import newsApiDefinition from './definition/dapp_doc';

const NEWS_REQUEST_OPTIONS = {
  timeout: 15000, // 请求超时时间
  headers: {
    'Content-Type': 'application/json;charset=UTF-8'
  }
};

const API = {
  // news api
  ...makeApi(process.env.NEWS_API, newsApiDefinition, NEWS_REQUEST_OPTIONS),
};

export default API;
