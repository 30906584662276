import pick from "@/utils/pick";
import { getHead, mergeHead, getGoogleAdScript } from "./common";

// Meta
function getMeta(data) {
  return [
    {
      name: "keywords",
      content: data.keyword,
    },
    {
      name: "description",
      content: data.description,
    },
  ];
}

// Open graph protocol
function getGalleryOGMeta(data) {
  if (!data.images) return [];
  return data.images.reduce(
    (a, t) => [
      ...a,
      { property: "og:image", content: t.image_url },
      { property: "og:image:alt", content: t.image_desc },
    ],
    []
  );
}

// Google Search Structure Data
function getGalleryStructureDataMeta(data) {
  if (!data.images) return [];
  return [
    ...(data.images || []).reduce(
      (a, t) => [
        ...a,
        {
          type: "application/ld+json",
          json: {
            "@context": "https://schema.org",
            "@type": "ImageObject",
            url: t.image_url,
            description: t.image_desc,
          },
        },
      ],
      []
    ),
    {
      type: "application/ld+json",
      json: {
        "@context": "https://schema.org",
        "@type": "NewsArticle",
        ...pick({
          headline: "title",
          image: "thumb",
          datePublished: "datePublished",
        })(data),
        author: {
          "@type": "Organization",
          name: data.siteName,
          logo: {
            "@type": "ImageObject",
            url: "https://www.tnaot.com/img/logo-256.png",
          },
        },
        publisher: {
          "@type": "Organization",
          name: data.siteName,
          logo: {
            "@type": "ImageObject",
            url: "https://www.tnaot.com/img/logo-256.png",
          },
        },
      },
    },
  ];
}

export default function getGalleryHead(gallery) {
  const data = pick({
    siteName: "siteName",
    pageLocale: "pageLocale",
    locale: 'locale',
    pageUrl: "pageUrl",
    mobileUrl: "mobileUrl",
    canonicalPageUrl: "canonicalPageUrl",
    title: "title",
    thumb: "thumbs.0",
    keyword: "keyword",
    description: "description",
    images: "images",
    datePublished: "release_timestamp,date-iso",
  })(gallery);

  return mergeHead(
    { meta: getMeta(data) },
    getHead(data),
    { meta: getGalleryOGMeta(data) },
    { script: [...getGalleryStructureDataMeta(data), ...getGoogleAdScript()] }
  );
}
