import { render, staticRenderFns } from "./default.vue?vue&type=template&id=e5e34048&scoped=true"
import script from "./default.vue?vue&type=script&lang=js"
export * from "./default.vue?vue&type=script&lang=js"
import style0 from "./default.vue?vue&type=style&index=0&id=e5e34048&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e5e34048",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HeaderNav: require('/code/tnaot_www_pc/components/HeaderNav.vue').default,TFooter: require('/code/tnaot_www_pc/components/TFooter.vue').default,BackToTop: require('/code/tnaot_www_pc/components/BackToTop.vue').default})
