
import Icon from './Icon.vue';
import LanguageSelect from './LanguageSelect.vue';
import { cloneDeep } from 'lodash';
import { LANGUAGE } from '@/constants/index';
import MxLink from './MxLink.vue';

const KM_DEFAULT_CHANNEL = [
  {
    name: 'home_page',
    id: 'home'
  },
];
const ZH_DEFAULT_CHANNEL = [
  {
    name: 'home_page',
    id: 'home'
  },
  {
    name: 'focus_news',
    id: 84
  },
  {
    name: '特色出品',
    id: 'topic'
  }
];
const MAX_NAV_WIDTH = 546;
const MEDIA_URL = process.env.MEDIA_URL;

export default {
  components: { Icon, LanguageSelect, MxLink },

  data() {
    return {
      MEDIA_URL,
      showObj: {
        channel: false,
        search: false
      },
      query: '',
      queryFilter: '',
      currentChannelIndex: 'home',
      prevNavList: [],
      nextNavList: [],
      allNavList: []
    };
  },

  computed: {
    language() {
      return this.$store.state.app.language;
    },
    isSearchPage() {
      return this.$route.path.includes('search');
    },
    isSelectedNext() {
      return this.nextNavList.some(item => item.id == this.currentChannelIndex);
    }
  },

  watch: {
    language() {
      this.getNav();
    },
    '$route.path': {
      handler: 'getCurrentPage',
      deep: true,
      immediate: true
    }
  },

  created() {
    this.getNav();
  },

  mounted() {
    document.addEventListener('click', this.registerListener, true);
  },

  beforeDestroy() {
    this.removeListener();
  },

  methods: {
    registerListener(e) {
      if (this.$refs.channel && !this.$refs.channel.contains(e.target)) {
        this.showObj.channel = false;
      }
      if (this.$refs.search && !this.$refs.search.contains(e.target)) {
        this.showObj.search = false;
      }
    },

    removeListener() {
      document.removeEventListener('click', this.registerListener);
    },

    getCurrentPage() {
      const { path } = this.$route;
      if (path.includes('channel')) {
        this.currentChannelIndex = path.split('/').reverse()[0];
      } else if (path.includes('topic-list')) {
        this.currentChannelIndex = 'topic';
      } else if (path === '/zh' || path === '/km') {
        this.currentChannelIndex = 'home';
      } else if (path.includes('/topic/')) {
        this.currentChannelIndex = this.findTopicIdByPath(path);
      } else {
        this.currentChannelIndex = -1;
      }
    },

    findTopicIdByPath(path) {
      const subjectId = path.split('/').reverse()[0];
      const item = this.allNavList.filter(
        item => item.subjectId == subjectId
      )[0];
      return item ? item.id : -1;
    },

    async getNav() {
      const channelList = await this.$API.articleGetChannel();
      const { language } = this;
      const list =
        language === LANGUAGE.ZH
          ? [...ZH_DEFAULT_CHANNEL, ...channelList]
          : [...KM_DEFAULT_CHANNEL, ...channelList];
      this.allNavList = cloneDeep(list);
      this.prevNavList = cloneDeep(list);
      this.nextNavList = [];
      this.$nextTick(() => {
        this.calculateNav();
      });
    },

    calculateNav() {
      if (!this.$refs.nav || this.isSearchPage) return;
      const width = Math.ceil(this.$refs.nav.getBoundingClientRect().width);
      const isBeyond = width >= MAX_NAV_WIDTH;
      if (isBeyond) {
        this.prevNavList = this.allNavList.slice(0, 5);
        this.nextNavList = this.allNavList.slice(5);
      }
    },

    showSearch() {
      this.showObj = {
        channel: false,
        search: true
      };
      this.queryFilter = '';
    },

    showChannel() {
      this.showObj = {
        channel: true,
        search: false
      };
    },

    jumpPage({ id, name, subjectId, channelType }) {
      this.currentChannelIndex = id;
      this.showObj.channel = false;

      switch (id) {
        case 'home':
          this.$router.push({
            path: this.localePath('/', this.$i18n.locale)
          });

          break;
        case 'topic':
          this.$router.push({
            path: this.localePath('/m/topic-list', this.$i18n.locale)
          });
          break;
        default:
          // 专题频道
          if (channelType === 1 && subjectId) {
            this.$router.push({
              path: this.localePath(`/m/topic/${subjectId}`, this.$i18n.locale)
            });
            return;
          }
          this.$router.push({
            path: this.localePath(`/m/channel/${id}`, this.$i18n.locale)
          });
          break;
      }
    },

    beforeSearch() {
      const { queryFilter } = this;
      if (!queryFilter || queryFilter.trim() === '') return;
      this.showObj.search = false;
      this.$store.commit('app/updateSearchContent', queryFilter);
      this.query = queryFilter;
      this.$router.push({
        path: this.localePath('/m/search', this.$i18n.locale)
      });
    },

    search() {
      const { query } = this;
      if (!query || query.trim === '') return;
      const { searchContent } = this.$store.state.app;
      if (query === searchContent) return;

      this.$store.commit('app/updateSearchContent', query);
    }
  }
};
